<script setup lang="ts">
import { useIntersectionObserver } from '@vueuse/core/index'

interface Props {
  contrastBackground?: boolean
}

withDefaults(defineProps<Props>(), {
  contrastBackground: false,
})
const target = ref(null)
const target2 = ref(null)
const target3 = ref(null)
const targetIsVisible = ref(false)
const targetIsVisible2 = ref(false)
const targetIsVisible3 = ref(false)

useIntersectionObserver(
  target,
  ([{ isIntersecting }], observerElement) => {
    targetIsVisible.value = isIntersecting
  },
)
useIntersectionObserver(
  target2,
  ([{ isIntersecting }], observerElement) => {
    targetIsVisible2.value = isIntersecting
  },
)
useIntersectionObserver(
  target3,
  ([{ isIntersecting }], observerElement) => {
    targetIsVisible3.value = isIntersecting
  },
)
const review = ref(null)
</script>

<template>
  <section class="h-[400vh] relative">
    <div ref="target" class="absolute top-[100vh]" />
    <div ref="target2" class="absolute top-[200vh]" />
    <div ref="target3" class="absolute top-[300vh]" />
    <div :class="contrastBackground ? 'bg-milky-200' : ''" class="w-full h-screen sticky top-0 z-40">
      <div class="max-w-[1012px] w-full px-4 mx-auto md:pt-[50vh] pt-[35vh] -translate-y-1/4 flex flex-row gap-10">
        <div class="flex md:flex-row flex-col gap-8 md:gap-20 ">
          <div class="flex flex-col gap-4 items-center justify-center max-w-[600px]">
            <h2 class="md:text-h2 text-m-h2">
              Мотивирует оставить отзыв, даже если клиент доволен
            </h2>
            <p class="md:text-primary text-m-primary">
              Ранг делает так, чтобы довольные клиенты захотели потратить время и оставить отзыв:
            </p>
            <ol class="list-disc self-start mx-5 md:gap-4 gap-1 flex flex-col md:text-primary text-m-primary">
              <li>
                <span class="transition-all duration-200" :class="[targetIsVisible ? 'bg-yellow-500' : 'text-black']">

                  обращается по имени, чтобы выделить СМС из кучи спама;
                </span>
              </li>
              <li>
                <span class="transition-all duration-200" :class="[targetIsVisible2 ? 'bg-yellow-500' : 'text-black']">
                  отправляет СМС в такое время, когда ваши клиенты охотнее оставляют отзывы;
                </span>
              </li>
              <li>
                <span class="transition-all duration-200" :class="[targetIsVisible3 ? 'bg-yellow-500' : 'text-black']">
                  мотивирует деньгами.
                </span>
              </li>
            </ol>
          </div>
          <div>
            <div class="relative bg-transparent md:bg-milky-200 p-0 md:px-12 md:pt-20 rounded-lg w-auto h-auto md:w-[388px] md:h-[380px]">
              <NuxtImg
                format="webp"
                alt="dialog"
                loading="lazy"
                class="min-w-[282px] w-[282px] absolute " src="/dialog.svg"
              />
              <div class="relative top-0 text-primary-small pl-8 pt-5 w-[264px]">
                <span class="transition-all duration-200" :class="[targetIsVisible ? 'bg-yellow-500' : 'text-black']">Олег</span>, как вам Суперклиника?
                Что мы можем улучшить, над чем поработать? Расскажите rang.ai/iuro87t
                <span class="transition-all duration-200" :class="[targetIsVisible3 ? 'bg-yellow-500' : 'text-black']">

                  В благодарность за ваше время мы пополним баланс вашего телефона на 200 рублей.
                </span>
              </div>
              <time :class="[targetIsVisible2 ? 'bg-yellow-500' : 'text-black']" class=" transition-all duration-200 absolute md:right-16 right-28 -bottom-16 md:bottom-20">21:00</time>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">

</style>
